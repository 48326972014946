import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const COOKIE_TERMS_LOCALES = {
	WOLTAIR_CZ: 'zasady-pouzivani-cookies',
	WOLTAIR_PL: 'polityka-cookies',
	WOLTAIR_DE: 'cookie-policy',
	WOLTAIR_IT: 'politica-dei-cookie'
}

export const COOKIE_TERMS = COOKIE_TERMS_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return COOKIE_TERMS === param
}) satisfies ParamMatcher
